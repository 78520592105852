<template>
  <div class="countdown" v-bind:class="{ 'public-countdown': preset === 'public' }">
    <div class="flap">
      <div class="type">Day</div>
      <div class="number">{{ days }}</div>
    </div>
    <div class="flap">
      <div class="type">Hour</div>
      <div class="number">{{ hours }}</div>
    </div>
    <div class="flap">
      <div class="type">Minute</div>
      <div class="number">{{ minutes }}</div>
    </div>
    <div class="flap">
      <div class="type">Second</div>
      <div class="number">{{ seconds }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Countdown',
  props: {
    preset: {
      type: String,
      default: '',
    },
  },
  components: {
  },
  computed: {
      delta: function () {
        const vm = this;
        const { presaleTime, reserveTime, publicTime, currentTime, closingTime } = vm.$store.state;
        if (presaleTime > currentTime) {
          return presaleTime - currentTime;
        } else if (reserveTime > currentTime) {
          return reserveTime - currentTime;
        } else if (publicTime > currentTime) {
          return publicTime - currentTime;
        } else if (closingTime > currentTime) {
          return closingTime - currentTime;
        }
        return 0;
      },
      // the time flaps
      days: function () {
        return this.getTimeArray(this.delta).days;
      },
      hours: function () {
        return this.getTimeArray(this.delta).hours;
      },
      minutes: function () {
        return this.getTimeArray(this.delta).minutes;
      },
      seconds: function () {
        return this.getTimeArray(this.delta).seconds;
      },
  },
  methods: {
    getTimeArray(delta) {
      if (this.$store.state.currentTime <= 0 || delta <= 0) {
        return { days: '-', hours: '-', minutes: '-', seconds: '-' };
      }
      const days = Math.floor(delta / 86400);
      const hours = Math.floor((delta - days * 86400) / 3600);
      const minutes = Math.floor((delta - days * 86400 - hours * 3600) / 60);
      const seconds = Math.floor(delta - days * 86400 - hours * 3600 - minutes * 60);
      return { days, hours, minutes, seconds };
    }
  },
  mounted() {
    const vm = this;
    setInterval(() => vm.$store.dispatch("updateCountDown"), 1000);
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.countdown {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: -0.5rem;
  gap: 1rem;

  .flap {
    justify-self: center;
    font-family: $font-primary;
    color: $white;
    width: 100%;
    text-align: center;

    .type {
      text-align: center;
      margin-bottom: 0.25rem;
      font-size: $font-xs;
    }

    .number {
      background: $pink-hot;
      padding: 0.1rem 0.75rem;
      border-radius: $radius-md;
      border: 3px solid $pink-dark;
      font-size: $font-md;
      line-height: 30px;
      height: 40px;
      box-shadow: inset 5px -5px 0px rgba(0, 0, 0, 0.4);
    }
  }
}

.card.public {
  .type {
    color: $black;
  }
  .number {
    background: $green-hot;
    color: $black;
  }
}

.card.reserve {
  .type {
    color: $black;
  }
  .number {
    background: $purple-hot;
    color: $black;
  }
}

.public-countdown {
  margin-top: 1rem;
  .type {
    color: $black;
  }
  .number {
    background: $green-hot !important;
    color: $black;
  }
}
</style>
