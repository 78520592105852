<template>
  <footer>
    Squishiverse (c) 2022. All rights reserved.
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {},
  components: {},
}
</script>

<style lang="scss" scoped>
footer {
  padding: 2rem;
  font-family: $font-primary;
  color: $white;
  font-size: $font-xs;
  text-align: center;
  background: $black;
  position: absolute;
  width: 100%;
  z-index: 999999;
  bottom: 0;
}
</style>
