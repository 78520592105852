import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";

export default {
    // web3
    web3: null,
    provider: null,
    account: null,

    // modals
    mintModal: false,

    // total minted
    mintStatus: {
        maxPerWallet: 1,
        mintRound: 0,
        publicSale: false,
        whitelistSale: false,
        land: [
            {
                id: 0,
                name: "Rare",
                price: "200000000000000000",
                supply: 2494,
                minted: 20,
                burnt: 0
            },
            {
                id: 1,
                name: "Epic",
                price: "400000000000000000",
                supply: 1500,
                minted: 10,
                burnt: 0
            },
            {
                id: 2,
                name: "Legendary",
                price: "750000000000000000",
                supply: 400,
                minted: 5,
                burnt: 0
            },
            {
                id: 3,
                name: "Mythic",
                price: "1250000000000000000",
                supply: 50,
                minted: 2,
                burnt: 0
            }
        ]
    },

    // presale merkle proof
    presaleTier: {
        tier: null,
        proof: ["0x"],
        root: null,
        valid: false,
        loaded: false,
    },

    // transaction information
    mintingLand: null,
    tx: {
        started: false,
        completed: false,
        failed: false,
        failedMsg: null,
        hash: false,
    },

    // countdown
    currentTime: 0,
    presaleTime: 1652472000,
    reserveTime: 1652472000 + (3600 * 4),
    publicTime: 1652472000 + (3600 * 4) + (3600 * 2),
    closingTime: 1652472000 + 86400,

    // web3 modal config
    providerOptions: {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                infuraId: process.env.VUE_APP_INFURA_ID,
                network: process.env.VUE_APP_CONTRACT_NETWORK,
            },
        },
        walletlink: {
            package: WalletLink,
            options: {
                appName: "Squishiland",
                infuraId: process.env.VUE_APP_INFURA_ID,
                chainId: process.env.VUE_APP_CONTRACT_NETWORK === "rinkeby" ? 4 : 1,
            },
        },
    },
}