<template>
  <div :class="`mint-pane__section ${land.name.toLowerCase()}`">
    <img src="@/assets/images/hero/scroll.png" class="scroll-bg" />
    <img :src="landImage" :class="`land-img ${land.name.toLowerCase()}`" />
    <h1>{{ land.name }} Land</h1>
    <h2 class="c-gold">{{ formattedPrice }} ETH</h2>
    <div class="cta">
      <QuantitySelector
        :min="1"
        :max="$store.state.mintStatus.maxPerWallet"
        v-model="amount"
      />
      <Button
        @click="mint()"
        :disabled="!canMint"
        v-if="land.minted < land.supply"
        >Mint Now</Button
      >
      <Button v-else :disabled="true" class="sold-out">Sold Out</Button>
      <label>{{ land.minted }} / {{ land.supply }} minted</label>
    </div>
  </div>
</template>

<script>
import QuantitySelector from "../ui/QuantitySelector.vue";
import Button from "../ui/Button.vue";
import ThePlagueAbi from "../../data/abi/ERC721.json";
import Web3 from "web3";

export default {
  props: ["land"],
  components: {
    QuantitySelector,
    Button,
  },
  computed: {
    canMint() {
      const vm = this;
      if (vm.$store.state.mintStatus.totalMinted >= 4444) {
        return false;
      }
      if (vm.$store.state.currentTime < vm.$store.state.presaleTime) {
        return false;
      }
      if (vm.$store.state.currentTime > vm.$store.state.closingTime) {
        return false;
      }
      return true;
    },
    landImage() {
      return require(`@/assets/images/lands/${this.land.name}.png`);
    },
    formattedPrice() {
      const vm = this;
      return (parseFloat(Web3.utils.fromWei(vm.land.price, "ether")) * vm.amount).toFixed(2);
    },
  },
  methods: {
    resetTransactionData() {
      const vm = this;
      vm.$store.state.tx = {
        started: false,
        completed: false,
        failed: false,
        failedMsg: null,
        hash: false,
      };
    },
    async mint() {
      const vm = this;
      let method;
      vm.$store.state.mintModal = true;
      vm.$store.state.mintingLand = Object.assign({}, vm.land, {
        quantity: vm.amount,
      });

      if (vm.$store.state.mintStatus.publicSale === true) {
        method = vm.getContract().methods.mintPublic(vm.land.id, vm.amount);
        await vm.mintUsingMethod(vm.formattedPrice, method);
      } else {
        if (vm.$store.state.presaleTier.valid !== true) {
          await vm.$store.commit("setTransactionState", {
            started: true,
            failed: true,
            failedMsg:
              "Minting is currently open to only whitelisted addresses.",
          });
          return;
        } else {
          console.log("presale", vm.$store.state.presaleTier);
          const { tier, proof } = vm.$store.state.presaleTier;
          method = vm
            .getContract()
            .methods.mintWhitelist(vm.land.id, tier, vm.amount, proof);
        }
        await vm.mintUsingMethod(vm.formattedPrice, method);
      }
    },
    async mintUsingMethod(totalEthCost, method) {
      const vm = this;
      const weiValue = Web3.utils.toWei(totalEthCost.toString(), "ether");
      vm.resetTransactionData();
      vm.$store.state.tx.started = true;
      let opts = {
        from: this.$store.state.account,
        value: weiValue,
      };
      try {
        let gasCost = await method.estimateGas(opts);
        console.log("gas_cost", gasCost);
        method
          .send({
            gas: parseInt(1.2 * gasCost),
            from: this.$store.state.account,
            value: weiValue,
          })
          .on("transactionHash", function (hash) {
            console.log("tx_hash", hash);
            vm.$store.commit("setTransactionState", { hash });
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            console.log("tx_confirm", [confirmationNumber, receipt]);
          })
          .on("receipt", function (receipt) {
            console.log("tx_succes", receipt);
            vm.$store.commit("setTransactionState", { completed: true });
            vm.$store.dispatch("fetchMintStats");
            // vm.$store.dispatch("fetchMintedSupply");
          })
          .on("error", function (error) {
            vm.$store.commit("setTransactionState", { failed: true });
            console.error("tx_fail", error);
          });
      } catch (err) {
        console.log(err);
        await vm.$store.commit("setTransactionState", { failed: true });
        try {
          const message = /execution reverted:\s(.*)/.exec(err.message)[1];
          await vm.$store.commit("setTransactionState", {
            failedMsg: message + ".",
          });
        } catch (regerr) {
          const possibleError = err.message || "";
          if (possibleError.includes("insufficient funds")) {
            await vm.$store.commit("setTransactionState", {
              failedMsg: "Not enough funds",
            });
          } else {
            await vm.$store.commit("setTransactionState", {
              failedMsg: "Transaction failed, unknown error",
            });
          }
        }
      }
    },
    getContract() {
      const vm = this;
      return new vm.$store.state.web3.eth.Contract(
        ThePlagueAbi,
        process.env.VUE_APP_CONTRACT_ADDRESS
      );
    },
  },
  data() {
    return {
      amount: 1,
    };
  },
};
</script>


<style lang="scss" scoped>
@keyframes bloat {
  0% {
    transform: rotate(-3deg) scale(1);
  }
  50% {
    transform: rotate(-3deg) scale(1.01);
  }
  100% {
    transform: rotate(-3deg) scale(1);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1px, 0, 0);
  }
}

@keyframes land-blinker {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: translateY(-20px); //scale(1.03);
  }
}

.mint-pane__section {
  position: relative;
  min-height: 25rem;
  margin: 3rem 0;
  padding-top: 5rem;
  transition: all 0.2s;
  opacity: 0.5;
  //   cursor: pointer;

  &.active,
  &.inactive {
    opacity: 1;
  }

  .scroll-bg {
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    position: absolute;
    pointer-events: none;
    height: 90%;
  }
  .land-img {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    pointer-events: none;

    &.mythic {
      margin-top: -7.5rem;
    }
    &.legendary {
      margin-top: -8.5rem;
    }
    &.epic {
      margin-top: -8.5rem;
    }
    &.rare {
      max-width: 225px;
      margin-top: -7.5rem;
    }
  }

  &:hover {
    // filter: drop-shadow(0 0px 200px rgba($black, 0.75));
    &.mythic {
      filter: drop-shadow(0 0px 200px rgba(#72fbfd, 1));
    }
    &.legendary {
      filter: drop-shadow(0 0px 200px rgba(#e89636, 1));
    }
    &.epic {
      filter: drop-shadow(0 0px 200px rgba(#8a2ef5, 1));
    }
    &.rare {
      filter: drop-shadow(0 0px 200px rgba(#b5e0e0, 1));
    }
    z-index: 999;
    animation: shake 0.5s ease-in-out forwards;
    .land-img {
      animation: land-blinker 1s ease-in-out infinite;
    }
  }
  &.sold-out {
    // opacity: 0.2;
    &:hover {
      // filter: unset;
      filter: drop-shadow(0 0px 200px rgba($red, 0.25));
      // animation: unset;
      .land-img {
        // animation: unset;
      }
    }
  }

  h1 {
    margin: 1rem 0;
    animation: bloat 2s ease-in-out infinite;
  }

  h2 {
    position: relative;
    z-index: 2;
  }

  .cta {
    display: grid;
    gap: 1rem;
    justify-content: center;
    margin: 1rem 0;
    button {
      //   width: auto;
      margin: 0;
    }
  }

  label {
    margin-top: 1rem;
    color: $black;
    font-family: $font-primary;
    font-size: $font-sm;
  }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
}
@media screen and (min-width: $screen-lg) {
  .mint-pane__section {
    margin: 3rem 0;
    padding-top: 10rem;
    .scroll-bg {
      height: unset;
    }
    .land-img {
      max-width: 400px;
      &.mythic {
        margin-top: -12rem;
      }
      &.legendary {
        margin-top: -13rem;
      }
      &.epic {
        margin-top: -13rem;
      }
      &.rare {
        max-width: 375px;
        margin-top: -12rem;
      }
    }

    h1 {
    }

    h2 {
    }

    .cta {
      button {
      }
    }
  }
}
@media screen and (min-width: $screen-xlg) {
  .mint-pane__section {
    min-height: 30rem;

    .scroll-bg {
    }
    .land-img {
      &.mystic {
      }
      &.legendary {
      }
      &.epic {
      }
      &.rare {
      }
    }

    h1 {
    }

    h2 {
    }

    .cta {
      button {
      }
    }
  }
}
@media screen and (min-width: $screen-xxlg) {
}
</style>