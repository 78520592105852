<template>
  <div class="hero__section">
    <div class="hero-bg"></div>
    <div class="container">
      <Navbar />
    </div>
    <div class="container">
      <div class="hero__content">
        <div
          class="intro"
          v-bind:class="{ hidden: $store.state.account !== null }"
        >
          <div class="intro__content">
            <h2>Welcome to</h2>
            <h1><span>Squishi</span><span>land</span></h1>
            <a href="https://play.squishiland.com" class="demo" target="_blank">
              <img src="@/assets/images/demo.png" />
              <span>Play Now</span>
              <img src="@/assets/images/demo.png" />
            </a>
            <!-- <Card
              title="Presale starts in"
              type="presale"
              v-if="$store.state.presaleTime >= $store.state.currentTime"
            >
              <Countdown />
            </Card>
            <Card
              title="Presale open! Reserve list sale in"
              type="presale"
              v-else-if="$store.state.reserveTime >= $store.state.currentTime"
            >
              <Countdown />
            </Card>
            <Card
              title="Reserve list open! Public sale in"
              type="reserve"
              v-else-if="$store.state.publicTime >= $store.state.currentTime"
            >
              <Countdown />
            </Card>
            <Card
              title="Public sale started! Closing in"
              type="public"
              v-else-if="$store.state.closingTime >= $store.state.currentTime"
            >
              <Countdown />
            </Card>
            <Card type="public" v-else>
              <Countdown />
            </Card>
            <Button class="connect" @click="connectWallet" :disabled="!canMint"
              >Connect Wallet</Button
            > -->
          </div>
        </div>
        <div class="land-panels" v-show="$store.state.account !== null">
          <MintPane
            v-for="land in landSorted"
            :key="land.id"
            :land="land"
            v-bind:class="{
              active: active === land.name,
              inactive: active === null,
              'sold-out': land.minted >= land.supply,
            }"
            @mouseover.native="active = land.name"
            @mouseleave.native="active = null"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../ui/Card";
import Button from "../ui/Button";
import Countdown from "./Countdown";
import Socials from "../../data/Socials.json";

import Navbar from "./Navbar.vue";
import MintPane from "./MintPane.vue";

export default {
  name: "Hero",
  props: {},
  components: {
    Card,
    Button,
    Countdown,
    Navbar,
    MintPane,
  },
  computed: {
    remainingAmount() {
      const vm = this;
      if (vm.$store.state.mintStatus.totalMinted === "...") {
        return vm.$store.state.mintStatus.totalMinted;
      }
      return 8888 - vm.$store.state.mintStatus.totalMinted;
    },
    canMint() {
      const vm = this;
      const { currentTime, presaleTime, closingTime } = vm.$store.state;
      return currentTime > presaleTime && currentTime < closingTime;
    },
    landSorted() {
      let land = [...this.$store.state.mintStatus.land];
      land.sort((a, b) => b.supply - a.supply);
      return land;
    },
  },
  methods: {
    connectWallet() {
      const vm = this;
      vm.$store.dispatch("connectWallet");
    },
  },
  data() {
    return {
      socials: Socials,
      active: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes bloat {
  0% {
    transform: rotate(-3deg) scale(1);
  }
  50% {
    transform: rotate(-3deg) scale(1.01);
  }
  100% {
    transform: rotate(-3deg) scale(1);
  }
}

@keyframes fade-in {
  to {
    opacity: 1;
    pointer-events: all;
  }
}

@keyframes bg-intro {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes bg-intro-col {
  0% {
    background: rgba(0, 0, 0, 0.9);
  }
  100% {
    background: rgba(0, 0, 0, 0);
  }
}

.hero__section {
  overflow-x: hidden;
  .hero-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    background: url("~@/assets/images/city.jpg") no-repeat;
    background-size: cover;
    background-position: bottom;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(5px);
      animation: bg-intro-col 1.5s ease forwards;
    }
  }
  .hero__content {
    position: relative;
    z-index: 1;
    text-align: center;
    color: white;
    min-height: 600px;
    margin-top: 6rem;
    display: grid;

    .land-panels {
      padding-top: 4rem;
      // padding: 3rem 0;
      display: grid;
      gap: 2rem;
      pointer-events: none;
      opacity: 0;
      animation: fade-in 0.2s linear forwards;
      animation-delay: 0.2s;
    }
    .intro {
      position: absolute;
      width: 100%;
      transition: opacity 0.2s;
      opacity: 1;
      pointer-events: all;
      overflow: hidden;
      // margin-top: 5rem;
      padding: 2rem 0;
      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
      &__content {
        max-width: 650px;
        margin: 0 auto;
        padding: 0 1rem;
        align-self: center;
        margin-bottom: 5rem;

        > .card {
          margin-top: 2rem;
        }
        > .demo {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          background: $gold;
          transition: all 0.2s;
          @include threedee-box(3px, $black);
          font-family: $font-primary;
          border-radius: $radius-md;
          color: $black;
          width: 200px;
          margin: 3rem auto 0 auto;
          padding: 0.15rem 0.1rem 0.25rem 0.1rem;
          font-size: $font-xs;
          img {
            width: 24px;
          }
          span {
            padding: 0 0.5rem;
          }
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      h1 {
        font-size: $font-lg;
        @include threedee-text(3px);
        transform: rotate(-3deg);
        animation: bloat 2s ease-in-out infinite;
        span {
          z-index: 1;
          position: relative;
          color: $red;
          margin-right: 3px;
        }
        span + span {
          z-index: 0;
          color: $green;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .hero__section {
    .hero-bg {
      &:before {
      }
    }
    .hero__content {
      margin-top: 8rem;

      .land-panels {
        padding-top: 3rem;
      }
      .intro {
        margin-top: 0;
        &.hidden {
        }

        &__content {
          > .card {
          }
        }
        h1 {
          font-size: $font-xlg;
          @include threedee-text(4px);
          span {
          }
          span + span {
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
  .hero__section {
    .hero-bg {
      &:before {
      }
    }
    .hero__content {
      .land-panels {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
  .hero__section {
    .hero-bg {
      &:before {
      }
    }
    .hero__content {
      margin-top: 8rem;
      .land-panels {
        padding: 6rem 0;
      }
      .intro {
        &.hidden {
        }

        &__content {
          width: 100%;
          padding: 0;
          > .card {
          }
        }
        h1 {
          font-size: $font-xxlg;
          span {
          }
          span + span {
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
