<template>
    <div :class="`card ${type}`">
        <div class="card-title" v-if="title.trim() === 'SquishiVerse'">
            <span>Squishi</span><span>Verse</span>
        </div>
        <div class="card-title" v-else-if="title">
            {{ title }}
        </div>
        <div class="card-body">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
      title: {
          type: String,
          default: '',
      },
      type: {
          type: String,
          default: '',
      },
  },
}
</script>

<style lang="scss" scoped>
.card {
    padding: 1rem 0;
    position: relative;
    &-title {
        color: $white;
        font-size: $font-xlg;
        text-align: center;
        @include threedee-text(3px);
        font-family: $font-primary;
        z-index: 0;
        span {
            z-index: 1;
            position: relative;
            color: $red;
            + span {
                color: $blue;
                z-index: 0;
            }
        }
    }
    &-body {
        @include threedee-box(3px);
        background: $white;
        padding: 1rem 1.5rem;
        overflow: hidden;
        font-weight: 600;
        white-space: break-spaces;
        z-index: 1;
        border-radius: $radius-md;
        position: relative;
        line-height: 150%;
        font-size: $font-xxs;
        p {
            margin: 0;
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $black;
                text-decoration: none;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

.image {
    .card-title {
        // display: none;
    }
    .card-body {
        padding: 0;
        line-height: 0;
        background: $black;
        img {
            max-width:100%;
            max-height:100%;
            height: auto;
        }
    }
}

.brand {
    .card-title {
        @include threedee-text(4px, $navy);
    }
    .card-body {
        color: $white;
        background: $blue;
        @include threedee-box(3px, $navy);
    }
}

.presale {
    .card-title {
        font-size: $font-md;
        margin-bottom: 2px;
        @include threedee-text(2px, darken($pink, 72%));
        color: $pink;
    }
    .card-body {
        @include threedee-box(3px, darken($pink, 72%));
        background: $pink;
    }
}

.reserve {
    .card-title {
        font-size: $font-md;
        margin-bottom: 2px;
        @include threedee-text(2px, darken($purple, 72%));
        color: $purple;
    }
    .card-body {
        @include threedee-box(3px, darken($purple, 72%));
        background: $purple;
    }
}

.public {
    .card-title {
        font-size: $font-md;
        margin-bottom: 2px;
        @include threedee-text(2px, darken($green, 72%));
        color: $green;
    }
    .card-body {
        @include threedee-box(3px, darken($green, 72%));
        background: $green;
    }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
    .brand {
        .card-title {
            font-size: $font-xxlg;
            @include threedee-text-extra(6px, 4px, $navy);
        }
    }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
