<template>
  <div class="navbar" v-bind:class="{ mobile: mobileMenu }">
    <div class="mobile-nav">
      <a href="/">
        <h2 class="brand">
          <img src="@/assets/images/cube.png" /><span>Squishi</span
          ><span>Land</span>
        </h2>
      </a>
      <button href="#" class="burger-menu" @click="mobileMenu = true">
        <span></span>
      </button>
    </div>

    <div
      v-bind:class="{ links: true, mobile: mobileMenu }"
      @click.self="mobileMenu = false"
    >
      <a href="/">
        <h2 class="brand">
          <img src="@/assets/images/cube.png" /><span>Squishi</span
          ><span>Land</span>
        </h2>
      </a>
      <div class="links-container">
        <div class="left"></div>
        <div class="right">
          <a :href="socials.opensea" target="_blank" class="social opensea" v-if="socials.opensea !== '#'">
            <img
              src="@/assets/images/socials/Opensea.svg"
              :style="{ width: '32px', height: '32px' }"
            />
            <span>Opensea</span>
          </a>
          <a :href="socials.etherscan" target="_blank" class="social opensea" v-if="socials.etherscan !== '#'">
            <img
              src="@/assets/images/socials/Etherscan.svg"
              :style="{ width: '32px', height: '32px' }"
            />
            <span>Etherscan</span>
          </a>
          <a :href="socials.discord" target="_blank" class="social discord">
            <img
              src="@/assets/images/socials/Discord.svg"
              :style="{ width: '32px', height: '32px' }"
            />
            <span>Discord</span>
          </a>
          <a :href="socials.twitter" target="_blank" class="social twitter">
            <img
              src="@/assets/images/socials/Twitter.svg"
              :style="{ width: '32px', height: '32px' }"
            />
            <span>Twitter</span>
          </a>
          <a :href="socials.gitbook" target="_blank" class="social twitter">
            <img
              src="@/assets/images/socials/GitBook.svg"
              :style="{ width: '32px', height: '32px' }"
            />
            <span>Documentation</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Socials from "../../data/Socials.json";

export default {
  name: "Hero",
  methods: {},
  data() {
    return {
      mobileMenu: false,
      socials: Socials,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: absolute;
  top: 1rem;
  right: 0;
  left: 0;
  a {
    text-decoration: none;
  }
  &.mobile {
    z-index: 9999;
    .brand {
      margin-top: 2rem;
    }
  }
  .brand {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 1rem 0;
    img {
      width: 50px;
      margin-right: 1rem;
    }
    span {
      z-index: 1;
      position: relative;
      color: $red;
      margin-top: -0.4rem;
      margin-right: 0.2rem;
      height: 100%;
      + span {
        color: $green;
        z-index: 0;
        // margin-left: 4px;
      }
    }
  }

  .left {
    a {
      position: relative;
    }
  }

  .mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    .burger-menu {
      padding: 1rem;
      // margin: 1rem;
      background: $white;
      border-radius: 10px;
      @include threedee-box(3px, black);
      border: none;
      > span {
        width: 24px;
        height: 10px;
        position: relative;
        display: inline-block;
        border-top: 3px solid $black;
      }
      span:after,
      span:before {
        content: "";
        width: 100%;
        height: 3px;
        left: 0;
        background-color: $black;
        position: absolute;
      }
      span:after {
        bottom: -2px;
      }
      span:before {
        top: 3px;
      }
    }
    .brand {
      span {
        display: none;
      }
    }
  }

  .links {
    display: none;
    &.mobile {
      > .brand {
        margin-top: 1rem;
        justify-content: center;
      }
      display: block;
      position: fixed;
      // background: rgba($black, 0.8);
      backdrop-filter: blur(10px);
      height: 100%;
      width: 100%;
      z-index: 99999;
      top: 0;
      .links-container {
        display: grid;
        background: white;
        // box-shadow: inset 0 0 100px rgba(white, 0.3);
        @include threedee-box(3px, black);
        // margin: 5rem 3rem;
        border-radius: 10px;
        // padding: 2rem;
        margin: 2rem;
        overflow-y: auto;
        max-height: 80vh;
        > * {
          display: grid;
          a {
            color: $black;
            text-decoration: none;
            font-family: $font-secondary;
            // backdrop-filter: blur(1px);
            // box-shadow: inset 0 0 50px rgba(white, 0.05);
            border-bottom: solid rgba($black, 1) 3px;
            padding: 1rem 1rem;
          }
        }
        > .right {
          img {
            display: none;
          }
          a:last-child {
            border-bottom: 0;
          }
        }
        > .left {
          a:first-child {
            border-top: solid rgba($black, 0.1) 1px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .navbar {
    .mobile-nav {
      .brand {
        span {
          display: block;
        }
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
  .navbar {
    // top: 1rem;
    // justify-content: flex-start;
    .mobile-nav {
      display: none;
    }

    .left {
      a {
        position: relative;
      }
    }
    .links {
      margin-top: 0;
      display: flex;
      width: 100%;
      .brand {
        // display: none;
        // margin-right: 2rem;
        position: relative;
        > .apy {
          position: absolute;
          right: -0.5rem;
          font-weight: normal;
          bottom: -0.75rem;
          transform: rotate(-5deg);
          font-size: $font-xxxs;
          @include threedee-text(2px, $black);
        }
      }
      .links-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0.75rem 0;
        .right {
          justify-self: flex-end;
          display: flex;
          align-items: center;
          > a {
            position: relative;
            display: flex;
            align-items: center;
            img + span {
              display: none;
              // position: absolute;
              // left: 0;
              // font-size: 11px;
              // width: 100%;
            }
            img + span + span {
              margin-left: 10px;
            }
            img {
              position: relative;
              width: 32px;
              margin-left: 2rem;
              transition: transform 0.1s;
              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }
      }
    }

    a {
      font-family: $font-primary;
      color: $white;
      font-size: $font-sm;
      @include threedee-text(2px, $black);
      text-decoration: none;
      // margin-top: -0.3rem;
      margin-right: 1.5rem;
      display: inline-block;
      transition: all 0.2s;
      &:hover {
        transform: rotate(-3deg) scale(1.1);
      }
    }

    .mobile a {
      margin-right: 0;
      transform: unset;
    }
    .right a {
      margin-right: 0;
      transform: scale(1.1);
    }
  }

  .hero-staking .social {
    filter: invert(1);
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>