<template>
  <div id="app">
    <Hero />
    <!-- <Footer /> -->
    <MintModal v-if="$store.state.mintModal" />
  </div>
</template>

<script>
import Hero from './components/Hero/Hero.vue'
import Footer from './components/Footer.vue'
import MintModal from "./components/MintModal/MintModal.vue"

export default {
  name: 'App',
  components: {
    Hero,
    Footer,
    MintModal,
  },
  mounted() {
    const vm = this;
    // vm.$store.dispatch("fetchMintedSupply");
    vm.$store.dispatch("fetchMintStats");
    setInterval(() => vm.$store.dispatch("fetchMintStats"), 60000);
  },
}
</script>

<style lang="scss">
#root {
  font-family: $font-secondary;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

h1 {
  font-family: $font-primary;
  font-size: $font-lg;
  margin: 0;
  color: $white;
  @include threedee-text(3px);
}

h2 {
  font-family: $font-primary;
  font-size: $font-md;
  margin: 0;
  color: $white;
  @include threedee-text(3px);
}

.c-red {
  color: #f83820;
}
.c-green {
  color: $green-hot;
}
.c-gold {
  color: $gold;
}

.container {
  width: 100%;
  max-width: $screen-lg;
  margin: 0 auto;
  position: relative;
}

.icon {
  height: 20px;
  width: 20px;
  vertical-align: top;
}

.mob-only {
  visibility: visible;
  opacity: 1;
}
.tab-above {
  visibility: hidden;
  opacity: 0;
}

//button
.styled-btn {
  padding: 0.75rem 1rem;
  transition: 0.8s;
  position: relative;
  background: #6cc52c;
  width: 15rem;
  margin: 1rem auto;
  box-shadow: 0px 10px 6px -5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

@media screen and(min-width:768px) {
  .mob-only {
    visibility: hidden;
    opacity: 0;
  }
  .tab-above {
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (min-width: $screen-md) {
}
@media screen and (min-width: $screen-lg) {
  .container {
    width: 90%;
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
