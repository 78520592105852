<template>
  <div class="qty-select">
    <div
      :class="{ minus: true, disabled: value - 1 < min }"
      @click="decrease()"
    >
      &minus;
    </div>
    <div class="amt">{{ value }}</div>
    <div @click="increase()" :class="{ plus: true, disabled: value + 1 > max }">
      &plus;
    </div>
  </div>
</template>

<script>
export default {
  name: "QuantitySelector",
  props: {
    min: Number,
    max: Number,
  },
  methods: {
    increase() {
      const vm = this;
      if (vm.value + 1 <= vm.max) {
        vm.value += 1;
        vm.$emit("change", vm.value);
      }
    },
    decrease() {
      const vm = this;
      if (vm.value - 1 >= vm.min) {
        vm.value -= 1;
        vm.$emit("change", vm.value);
      }
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      value: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.qty-select {
  color: black;
  display: inline-flex;
  font-weight: 600;
  .amt {
    font-family: $font-primary;
    padding: 0.75rem 4rem;
    font-size: $font-sm;
    line-height: 1.5rem;
    min-width: 1.95rem;
  }
  .minus,
  .plus {
    display: flex;
    align-items: center;
    background: rgba($green, 0.2);
    padding: 0 1rem;
    line-height: 0;
    font-size: 1.25rem;
    border-radius: 100px;
    border: 3px solid $black;
    cursor: pointer;
    transition: all 0.2s;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }
    &:hover {
      background: $green;
    }
  }
  .minus {
    background: rgba($red, 0.2);
    &:hover {
      background: $red;
    }
  }
}
</style>
