<template>
  <button @click="!disabled && $emit('click')" v-bind:class="{ disabled }">
    <slot />
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  background: $white;
  border: 0;
  font-family: $font-primary;
  color: $black;
  padding: 0.25rem 1rem;
  border-radius: $radius-md;
  margin-top: 1rem;
  font-size: $font-sm;
  @include threedee-box(3px, $black);
  width: 100%;
  cursor: pointer;
  transition: all 0.2s;

  &.mint {
    background: $green;
    color: $navy;
    @include threedee-box(3px, $navy);
  }

  &.pink {
    background: $pink;
    color: $navy;
    @include threedee-box(3px, $navy);
  }

  &.sold-out {
    background: lighten($red, 20%);
    color: $navy;
    @include threedee-box(3px, $navy);
  }

  &.connect {
    background: $blue;
    color: $navy;
    @include threedee-box(3px, $navy);
  }

  &.mobile {
    background: $green;
    color: $navy;
    @include threedee-box(3px, $navy);
  }

  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &:hover {
    transform: scale(1.05);
  }
}
</style>
