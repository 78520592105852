import state from "../state";
import Web3 from "web3";
import Web3Modal from "web3modal";
import axios from "axios";

import SquishiverseAbi from "../../data/abi/ERC721.json";

export default {
  async updateCountDown(context) {
    const currentTime = parseInt(new Date().getTime() / 1000);
    context.commit("setCurrentTime", currentTime);
    // console.log({currentTime})
    // const publicSale = state.publicTime - currentTime <= 0;
    // const whitelistSale = !publicSale && state.presaleTime - currentTime <= 0;
    // context.commit("setMintStatus", {
    //   ...state.mintStatus,
    //   ...{ publicSale, whitelistSale }
    // });
  },
  async connectWallet(context) {
    console.log("Set Network", process.env.VUE_APP_CONTRACT_NETWORK);
    const web3modal = new Web3Modal({
      network: process.env.VUE_APP_CONTRACT_NETWORK,
      cacheProvider: false,
      providerOptions: state.providerOptions,
    });
    const provider = await web3modal.connect();
    context.commit("setProvider", provider);
    context.commit("setWeb3", new Web3(state.provider));
    context.commit("setAccount", (await state.web3.eth.getAccounts())[0] || null);
    await context.dispatch("fetchTierProofs", state.account);
  },
  async fetchTierProofs(context, account) {
    console.log("fetchTierProofs");
    const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/whitelist/${account}`);
    if (data.success === true) {
      let presaleTier = data.message ?? false;
      // no whitelist for the user
      if (presaleTier === false) {
        // await context.dispatch("fetchRemainingMints", { address: state.account });
        await context.commit("setPresaleTier", Object.assign(state.presaleTier, { loaded: true }));
      } else {
        // user has a whitelist for a tier
        if (!presaleTier.proof.length) {
          presaleTier.proof = ["0x"];
        }
        // await context.dispatch("fetchRemainingMints", { address: state.account });
        await context.commit("setPresaleTier", Object.assign({}, presaleTier, { loaded: true }));
      }
    }
  },
  async fetchMintedSupply(context) {
    console.log("fetchMintedSupply");
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        `https://${process.env.VUE_APP_CONTRACT_NETWORK}.infura.io/v3/${process.env.VUE_APP_INFURA_ID}`
      )
    );
    const contract = new web3.eth.Contract(
      SquishiverseAbi,
      process.env.VUE_APP_CONTRACT_ADDRESS
    );
    const totalMinted = await contract.methods.totalSupply().call();
    await context.commit("setMintStatus", { ...state.mintStatus, ...{ totalMinted } });
  },
  async fetchMintStats(context) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/stats`);
      console.log("mintedSupply", data.message)
      await context.commit("setMintStatus", {
        ...state.mintStatus,
        ...data.message,
      });
    } catch (err) {
      console.log("fetchMintStats FAIL!", err)
    }
  },
};
