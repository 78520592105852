export default {
    setProvider(state, params) {
        return (state.provider = params);
    },
    setWeb3(state, params) {
        return (state.web3 = params);
    },
    setAccount(state, params) {
        return (state.account = params);
    },
    setPresaleTier(state, params) {
        return (state.presaleTier = params);
    },
    setMintStatus(state, params) {
        return (state.mintStatus = params);
    },
    setCurrentTime(state, params) {
        return (state.currentTime = params);
    },
    setTransactionState(state, params) {
        state.tx = Object.assign(
            {},
            state.tx,
            params
        )
    },
};